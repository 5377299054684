<template>
  <v-overlay color="white" absolute opacity='.8' :value="isLoading" z-index="1">
    <v-progress-circular indeterminate color="secondary" />
  </v-overlay>
</template>

<script>
  export default {
    name: 'LoadingGroupComponent',

    props: {
      isLoading: {
        type: Boolean,
        required: true,
        default: false
      }
    }
  }
</script>

<style scoped>

</style>