<template>
  <v-card-text ref="topGroup">
    <LoadingGroupComponent :is-loading="loading[group.id]" />
    <div
      v-for="(row, i) in paginatedStructuresGroupsItems(rows)"
      v-bind:key="row.groupRow"
    >
      <v-card class="mb-3 ml-1 mr-1" elevation="0" width="100%" outlined>
        <div class="d-flex justify-end">
          <DeleteGroupButtonComponent @update:delete="$emit('delete:group', $event)" :rows="rows" :index="i" />
        </div>
        <v-card-text>
          <v-form :ref="'formGroup' + i" v-model="valid">
            <StructureValuesComponent
              :is-group="isGroup"
              @loading:update="$set(loading, group.id, $event)"
              :nomenclators="nomenclators"
              :valid="valid"
              :ref-key="`${row.groupRow}-${i}`"
              :config="config"
              :group="group"
              :values="row.values"
              @bug_refresh="$emit('bug_refresh', $event)"
            />
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-card-actions>
      <span class="group-name__footer">
        {{ translate(group.languageKey, true) }}
      </span>
      <v-spacer></v-spacer>
      <FooterPaginationComponent
        style="width: 40%"
        :valid="valid"
        :amount="10"
        :page="currentStructureGroupPage"
        :per-page="structuresGroupPerPage"
        :values="rows"
        :items-per-page="[5, 10, 20]"
        @update:items="updateItems"
      />
      <v-text-field
          v-if="maxPageNumber > 1"
          v-model="jumpToPage"
          type="number"
          :min="1"
          color="secondary"
          :max="maxPageNumber"
          :label="$t('records.fields.goToPage')"
          :rules="validateMoreThan(1, maxPageNumber)"
          style="width: 0; max-width: 20%"
      >
        <template #append>
          <v-btn elevation="0" tile @click="goToPage">{{ $t('records.buttoms.go') }}</v-btn>
        </template>
      </v-text-field>
    </v-card-actions>
  </v-card-text>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
  import StructureValuesComponent from "@/module/prerecords/commons/StructureValuesComponent"
  import FooterPaginationComponent from "@/module/prerecords/commons/FooterPaginationComponent"
  import LoadingGroupComponent from "@/module/prerecords/commons/LoadingGroupComponent"
  import LanguajeService from '@/services/LanguajeService'
  import DeleteGroupButtonComponent from '@/module/prerecords/commons/DeleteGroupButtonComponent'

  export default {
    name: "GroupStructuresComponent",
    components: {
      DeleteGroupButtonComponent,
      LoadingGroupComponent,
      FooterPaginationComponent,
      StructureValuesComponent,
    },
    props: {
      isGroup: {
        type: Boolean,
        required: true
      },
      config: {
        type: Object,
        required: true,
      },
      group: {
        type: Object,
        required: true,
      },
      rows: {
        type: Array,
        required: true,
      },
      nomenclators: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        currentStructureGroupPage: 1,
        structuresGroupPerPage: 10,
        valid: false,
        source: null,
        loading: {},
        isHover: false,
        jumpToPage: 1,
        maxPageNumber: null
      }
    },

    watch: {
      valid() {
        const valid = this.$refs["formGroup0"][0].validate()

        this.$emit("validation:update", {
          valid: valid,
          group: this.group.id,
        }) // Emitir el evento personalizado con el nuevo valor
      },
    },

    computed: {
      ...mapGetters({
        validateMoreThan: 'general/validateMoreThan'
      }),
      paginatedStructuresGroupsItems() {
        const startIndex =
          (this.currentStructureGroupPage - 1) * this.structuresGroupPerPage
        const endIndex = startIndex + this.structuresGroupPerPage

        return rows => rows.slice(startIndex, endIndex)
      },
    },

    mounted() {
      this.$refs["formGroup0"][0].resetValidation()

      this.$root.$on("update:page-group", newOptions => {
        this.updateItems(newOptions)
      })

      this.maxPageNumber = Math.ceil(this.rows.length / this.structuresGroupPerPage);
    },

    methods: {
      ...mapActions({
        searchProcessStructure: "proccess/searchProcessStructure",
      }),
      updateItems(newOptions) {
        this.currentStructureGroupPage = newOptions.page
        this.structuresGroupPerPage = newOptions.itemsPerPage

        this.maxPageNumber = Math.ceil(this.rows.length / this.structuresGroupPerPage);
        this.jumpToPage = newOptions.page;

        if (newOptions.isAdd) {
          return;
        }

        const targetElement = this.$refs.topGroup;
        const previousElement = targetElement.previousElementSibling;

        if (previousElement) {
          const secondPreviousElement = previousElement.previousElementSibling;
          if (secondPreviousElement) {
            let rect = secondPreviousElement.getBoundingClientRect();
            let offsetTop = window.pageYOffset + rect.top - 80; // 100px por encima del elemento
            window.scrollTo({top: offsetTop, behavior: 'smooth'});
          }
        }
      },
      goToPage() {
        if ((this.jumpToPage && this.jumpToPage >= 1) && (this.jumpToPage <= this.maxPageNumber)) {
          this.currentStructureGroupPage = parseInt(this.jumpToPage)
        }

        const targetElement = this.$refs.topGroup;
        const previousElement = targetElement.previousElementSibling;

        if (previousElement) {
          const secondPreviousElement = previousElement.previousElementSibling;
          if (secondPreviousElement) {
            let rect = secondPreviousElement.getBoundingClientRect();
            let offsetTop = window.pageYOffset + rect.top - 80; // 100px por encima del elemento
            window.scrollTo({top: offsetTop, behavior: 'smooth'});
          }
        }
      },
      // Translate function (consume Language Service)
      translate(key) {
        return LanguajeService.getKey3(key)
      },
    },

    destroyed() {
      this.$root.$off("update:page-group");
    }
  }
</script>

<style scoped type="scss">
.group-name__footer {
  color: #5ea2a359;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  padding: 4px 10px;
  background: rgb(172 237 221 / 44%);
  border-radius: 0.2em 0.2em 0 0;
  transition: all 500ms;

  &:hover {
    background: rgb(55, 169, 140);
    color: white;
  }
}
</style>
